/* eslint-disable no-restricted-globals */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-danger */
import React from 'react';
import { graphql } from 'gatsby';
import showdown from 'showdown';
import scrollTo from 'gatsby-plugin-smoothscroll';
import { useLocation } from '@reach/router';
import withRoleRequired from '../../../../lib/withRoleRequired';
import Layout from '../../../../components/layout';
import SEO from '../../../../components/seo';
import { Persona, PersonaQueryData, Breadcrumb } from '../../../../types/Global';
import { mapEdgesToNodes, buildPersona } from '../../../../lib/helpers';


interface Props {
  data: {
    allPersonasCsv: PersonaQueryData
  },
  params: {
    scenario: string;
  }  
}

interface ChapterLinkProps {
  link: string
  image?: string
  title: string
}

interface LocationState {
  scenarioName: string
  scenarioSlug: string
}

const ChapterLink: React.FunctionComponent<ChapterLinkProps> = ({ link, image, title}) => (
  <div
    className="bg-dstl-light-gray hover:bg-dstl-crimson flex items-center my-2 cursor-pointer"
    onClick={() => scrollTo(link)}
  >
    {image &&
      <img
        className="h-12 mx-auto flex-none"
        alt={title}
        src={image}
      />
    }
    <div className="h-4 flex-1 font-bold mx-4">{title}</div>
  </div>  
)

interface DetailProps {
  title: string
  value?: string
}

const Detail: React.FunctionComponent<DetailProps> = ( {title, value }) => (
  <div className="px-4">
    <span className="text-dstl-crimson text-sm">{title} : </span>
    <span className="text-sm">{value}</span>
  </div>
)

showdown.extension('solidBullets', () => ({
  type    : 'html', 
  regex   : '<ul>', 
  replace : '<ul class="list-disc">'
}));
showdown.extension('blackBullets', () => ({
  type    : 'html', 
  regex   : '<li>', 
  replace : '<li class="text-black ml-4">'
}));

const converter = new showdown.Converter({extensions: ['solidBullets', 'blackBullets']});

interface SectionProps {
  title: string
  value?: string
}

const Section: React.FunctionComponent<SectionProps> = ( {title, value }) => (
  <div className="m-2 px-4">
    <div className="text-dstl-crimson text-lg font-bold">{title}</div>
    <div className="mt-2" dangerouslySetInnerHTML={{ __html: `${converter.makeHtml(value || "")}` }} />
  </div>
)

const PersonaTemplate: React.FunctionComponent<Props> = ({ data, params }) => {
  const { scenario } = params;
  let breadcrumbs:Breadcrumb[] = [];
  const location = useLocation()
  if (location.state) {
    const locationState = location.state as LocationState;
    breadcrumbs=[
      { label: 'Scenarios', link: '/scenarios' },
      { label: locationState.scenarioName, link: `/scenarios/${locationState.scenarioSlug}`},
      { label: 'Personas', link: `/scenarios/${locationState.scenarioSlug}/personas`}
    ];
  }

  const personas: Persona[] = mapEdgesToNodes(data.allPersonasCsv) || [];
  const thisPersona = buildPersona(personas, scenario);
  if (!thisPersona) { return null;}
  const { name, age, placeOfBirth, currentLocation, ethnicGroup, religion, nationality } = thisPersona;
  const { currentJob, residentialStatus, relationshipStatus, qualifications, health, careerGoals, worriesAndConcerns } = thisPersona;
  const { careerInterestInDefence, potentialAttractions, potentialBlockers } = thisPersona;
  const { theirPersonalNarrative } = thisPersona;
  const { profileImage, viewImage, storyImage } = thisPersona;
  return (
    <Layout
      pageHeader={name}
      breadcrumbs={breadcrumbs}
      moreInfo="scenarios/personas"
    >
      <SEO title={name || "Persona"} />    
      <div className="px-4 py-4 mx-auto md:px-8 md:py-4">
        <main className="pb-8">
          <div className="mx-auto px-4 sm:px-6 lg:px-8">
            {/* Main 3 column grid */}
            <div className="grid grid-cols-1 gap-4 items-start lg:grid-cols-3 lg:gap-8">
              {/* Left column */}
              <div className="grid grid-cols-1 gap-4 lg:col-span-2 order-last lg:order-first">
                <section aria-labelledby="section-1-title">
                  <h2 className="sr-only" id="section-1-title">
                    {thisPersona.name}
                  </h2>
                  <div className="bg-white mb-16 max-h-screen">

                    <div
                      id="chapter-profile"
                      className="bg-dstl-light-gray grid grid-cols-1 md:grid-cols-4 my-4"
                    >
                      <div className="grid grid-cols-1 content-start">
                        <img
                          className="w-full m-2 col-span-1"
                          alt="Profile"
                          src={profileImage}
                        />
                        <Detail title="Age" value={age} />
                        <Detail title="Place of birth" value={placeOfBirth} />
                        <Detail title="Current location" value={currentLocation} />
                        <Detail title="Ethnic group" value={ethnicGroup} />
                        <Detail title="Religion" value={religion} />
                        <Detail title="Nationality" value={nationality} />
                      </div>
                      <div className="col-span-1 md:col-span-3">
                        <div className="m-2 px-4 text-2xl font-bold"><h2>Profile</h2></div>
                        <Section title="Current job" value={currentJob} />
                        <Section title="Residential status" value={residentialStatus} />
                        <Section title="Relationship status" value={relationshipStatus} />
                        <Section title="Qualifications" value={qualifications} />
                        <Section title="Health" value={health} />
                        <Section title="Career goals" value={careerGoals} />
                        <Section title="Worries and concerns" value={worriesAndConcerns} />
                      </div>
                    </div>
                    <div
                      id="chapter-view"
                      className="bg-dstl-light-gray grid grid-cols-1 md:grid-cols-4 my-4"
                    >
                      <img
                        className="w-full m-2 col-span-1"
                        alt="View of Defence"
                        src={viewImage}
                      />
                      <div className="col-span-1 md:col-span-3">
                        <div className="m-2 px-4 text-2xl font-bold"><h2>View of Defence</h2></div>
                        <Section title="Career interest in Defence" value={careerInterestInDefence} />
                        <Section title="Potential attractions" value={potentialAttractions} />
                        <Section title="Potential blockers" value={potentialBlockers} />
                      </div>
                    </div>
                    <div
                      id="chapter-story"
                      className="bg-dstl-light-gray grid grid-cols-1 md:grid-cols-4 my-4"
                    >
                      <img
                        className="w-full m-2 col-span-1"
                        alt="Personal Story"
                        src={storyImage}
                      />
                      <div className="col-span-1 md:col-span-3">
                        <div className="m-2 px-4 text-2xl font-bold"><h2>Personal Story</h2></div>
                        <Section title="Their personal narrative" value={theirPersonalNarrative} />
                      </div>
                    </div>
                    <div>&nbsp;</div>
                  </div>
                </section>
              </div>

              {/* Right column */}
              <div className="grid grid-cols-1 hidden lg:block">
                <section aria-labelledby="section-2-title">
                  <h2 className="sr-only" id="section-2-title">
                    Chapters
                  </h2>
                  <div className="bg-white overflow-hidden border-l-2 fixed">
                    <div className="p-6">
                      <ChapterLink link="#chapter-profile" image={profileImage} title="Profile" />
                      <ChapterLink link="#chapter-view" image={viewImage} title="View of Defence" />
                      <ChapterLink link="#chapter-story" image={storyImage} title="Personal Story" />
                    </div>
                  </div>
                </section>
              </div>

            </div>
          </div>
        </main>
      </div>

    </Layout>
  )
}

export const pageQuery = graphql`
  query($persona: String) {
    allPersonasCsv(filter: {persona: { eq: $persona }}) {
      edges {
        node {
          persona
          scenario
          name
          age
          placeOfBirth
          currentLocation
          ethnicGroup
          religion
          nationality
          currentJob
          residentialStatus
          relationshipStatus
          qualifications
          health
          careerGoals
          worriesAndConcerns
          careerInterestInDefence
          potentialAttractions
          potentialBlockers
          theirPersonalNarrative
          profileImage
          viewImage
          storyImage
        }
      }
    }
  }
`

export default withRoleRequired(PersonaTemplate);
