/* eslint-disable @typescript-eslint/no-explicit-any */

import { Persona } from '../types/Global';

export function mapEdgesToNodes(data: any): any[] {
  if (!data?.edges) return data || []
  return data.edges.map((edge: any) => edge.node)
}

export function cn(...args: any): any[] {
  return args.filter(Boolean).join(' ')
}

export function buildPersona(allPersonas: Persona[], scenarioSlug: string): Persona | undefined {
  const blankScenarioPersona = allPersonas.find((persona) => persona.scenario === "");
  const thisScenarioPersona = allPersonas.find((persona) => persona.scenario === scenarioSlug);
  const mergedPersona = {
    persona: (thisScenarioPersona?.persona !== "" ? thisScenarioPersona?.persona : blankScenarioPersona?.persona) || "",
    scenario: thisScenarioPersona?.scenario !== "" ? thisScenarioPersona?.scenario : blankScenarioPersona?.scenario,
    name: thisScenarioPersona?.name !== "" ? thisScenarioPersona?.name : blankScenarioPersona?.name,
    intro: thisScenarioPersona?.intro !== "" ? thisScenarioPersona?.intro : blankScenarioPersona?.intro,
    age: thisScenarioPersona?.age !== "" ? thisScenarioPersona?.age : blankScenarioPersona?.age,
    placeOfBirth: thisScenarioPersona?.placeOfBirth !== "" ? thisScenarioPersona?.placeOfBirth : blankScenarioPersona?.placeOfBirth,
    currentLocation: thisScenarioPersona?.currentLocation !== "" ? thisScenarioPersona?.currentLocation : blankScenarioPersona?.currentLocation,
    ethnicGroup: thisScenarioPersona?.ethnicGroup !== "" ? thisScenarioPersona?.ethnicGroup : blankScenarioPersona?.ethnicGroup,
    religion: thisScenarioPersona?.religion !== "" ? thisScenarioPersona?.religion : blankScenarioPersona?.religion,
    nationality: thisScenarioPersona?.nationality !== "" ? thisScenarioPersona?.nationality : blankScenarioPersona?.nationality,
    currentJob: thisScenarioPersona?.currentJob !== "" ? thisScenarioPersona?.currentJob : blankScenarioPersona?.currentJob,
    residentialStatus: thisScenarioPersona?.residentialStatus !== "" ? thisScenarioPersona?.residentialStatus : blankScenarioPersona?.residentialStatus,
    relationshipStatus: thisScenarioPersona?.relationshipStatus !== "" ? thisScenarioPersona?.relationshipStatus : blankScenarioPersona?.relationshipStatus,
    qualifications: thisScenarioPersona?.qualifications !== "" ? thisScenarioPersona?.qualifications : blankScenarioPersona?.qualifications,
    health: thisScenarioPersona?.health !== "" ? thisScenarioPersona?.health : blankScenarioPersona?.health,
    careerGoals: thisScenarioPersona?.careerGoals !== "" ? thisScenarioPersona?.careerGoals : blankScenarioPersona?.careerGoals,
    worriesAndConcerns: thisScenarioPersona?.worriesAndConcerns !== "" ? thisScenarioPersona?.worriesAndConcerns : blankScenarioPersona?.worriesAndConcerns,
    careerInterestInDefence: thisScenarioPersona?.careerInterestInDefence !== "" ? thisScenarioPersona?.careerInterestInDefence : blankScenarioPersona?.careerInterestInDefence,
    potentialAttractions: thisScenarioPersona?.potentialAttractions !== "" ? thisScenarioPersona?.potentialAttractions : blankScenarioPersona?.potentialAttractions,
    potentialBlockers: thisScenarioPersona?.potentialBlockers !== "" ? thisScenarioPersona?.potentialBlockers : blankScenarioPersona?.potentialBlockers,
    theirPersonalNarrative: thisScenarioPersona?.theirPersonalNarrative !== "" ? thisScenarioPersona?.theirPersonalNarrative : blankScenarioPersona?.theirPersonalNarrative,    
    profileImage: thisScenarioPersona?.profileImage !== "" ? thisScenarioPersona?.profileImage : blankScenarioPersona?.profileImage,
    viewImage: thisScenarioPersona?.viewImage !== "" ? thisScenarioPersona?.viewImage : blankScenarioPersona?.viewImage,
    storyImage: thisScenarioPersona?.storyImage !== "" ? thisScenarioPersona?.storyImage : blankScenarioPersona?.storyImage,
  };
  return mergedPersona;
}
